import { Injectable } from '@angular/core';
import { State } from '../interfaces/state';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor() { }

  public getList(): Array<State> {
    const states: Array<State> = [
      {
        id: 1,
        final: 0,
        name: 'Abierto'
      },
      /* {
        id: 3,
        final: 0,
        name: 'Solucionado',
      }, */
      {
        id: 4,
        final: 1,
        name: 'Cerrado'
      },
      {
        id: 7,
        final: 0,
        name: 'Gestionado'
      },
      {
        id: 8,
        final: 0,
        name: 'En Validación'
      },
    ];

    return states;
  }
}
