import { Injectable } from '@angular/core';
import { ProxyService } from '../providers/proxy/proxy.service';


@Injectable({
  providedIn: 'root'
})
export class ConsultantService {

  constructor(
    private proxy: ProxyService
  ) { }

  public getList() {
    return this.proxy.get('/asesores');
  }
}
