import { Injectable } from '@angular/core';
import { Channel } from '../interfaces/channel';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {

  constructor() { }

  public getList(): Array<Channel> {
    const causales: Array<Channel> = [
      {
        id: 1,
        name: 'Chat'
      },
      {
        id: 2,
        name: 'Callback'
      },
      {
        id: 3,
        name: 'Correo electrónico'
      },
      {
        id: 4,
        name: 'Coljuegos'
      },
      {
        id: 5,
        name: 'Canales digitales'
      },
      {
        id: 6,
        name: 'Retail - Embajadores'
      },
      {
        id: 7,
        name: 'Línea preferencial - VIP'
      },
      {
        id: 8,
        name: 'Presencial - Callback'
      }
    ];

    return causales;
  }
}
