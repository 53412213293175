import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { Router } from '@angular/router';
import { ProxyService } from '../../shared/providers/proxy/proxy.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  //public ws = '';
  public error = '';
  public login_data = {
    username: '',
    password: ''
  };

  constructor(
    private global: GlobalService,
    private proxy: ProxyService,
    private router: Router
  ) { }

  ngOnInit() {
    if (localStorage.getItem('act_user') && localStorage.getItem('act_user') != null) {
      this.global.USER_DATA = JSON.parse(localStorage.getItem('act_user'));
      this.router.navigateByUrl('/');
    }
  }

  btnLoginClick() {
    this.error = '';

    this.proxy.post('/login', JSON.stringify(this.login_data)).then(
      (response: any) => {
        if (response.status == 'success') {
          const payload = {
            USER_DATA: {
              id: response.data.id,
              perfil_id: response.data.perfil_id,
              name: response.data.nombre,
              lastname: response.data.apellido,
              email: response.data.correo
            }
          };

          this.global.USER_DATA = payload.USER_DATA;
          let timestamp = new Date();
          localStorage.setItem('fecha_hora_actual', JSON.stringify(timestamp));
          localStorage.setItem('act_user', JSON.stringify(payload.USER_DATA));
          this.router.navigateByUrl('/');
        } else {
          this.error = response.message;
        }
      },
      (err) => {
        this.error = err.message;
      }
    );
  }

  /* pruebaws() {

    this.proxy.getws('http://demos.complemento360.com/betickets-api/app/getWebServiceUser.php').then(
      (response: any) => {
        this.ws = response.data;
        console.log(this.ws);
      },
      
    );
  } */


}
