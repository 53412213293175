import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { GuardService } from './shared/services/guard.service';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { TicketFormComponent } from './pages/ticket-form/ticket-form.component';
import { TicketsResolver } from './shared/resolvers/tickets.resolver';
import { AccountComponent } from './pages/account/account.component';
import { TicketClientComponent } from './pages/ticket-client/ticket-client.component';


const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [ GuardService ] },
  { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [ GuardService ] },
  { path: 'tickets', component: TicketsComponent, pathMatch: 'full', canActivate: [ GuardService ], resolve: { tickets: TicketsResolver } },
  { path: 'ticket/nuevo/:number', component: TicketFormComponent, canActivate: [ GuardService ] },
  { path: 'ticket/cliente', component: TicketClientComponent, canActivate: [ GuardService ] },
  { path: 'ticket/:number', component: TicketDetailComponent, canActivate: [ GuardService ] },
  { path: 'clients', component: ClientsComponent, pathMatch: 'full', canActivate: [ GuardService ], },
  { path: 'account', component: AccountComponent, pathMatch: 'full', canActivate: [ GuardService ], },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'auditoria', loadChildren: () => import('./pages/auditoria/auditoria.module').then(m => m.AuditoriaModule) },
  // { path: '**', redirectTo: '/' }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload',
  enableTracing: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [
    TicketsResolver
  ]
})
export class AppRoutingModule { }
