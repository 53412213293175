import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';

import { ClientService } from 'src/app/shared/services/client.service';
import { Client } from 'src/app/shared/interfaces/client';
import { Router } from '@angular/router';



import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalAlert } from 'src/app/app.component';
import { TicketService } from 'src/app/shared/services/ticket.service';

@Component({
  selector: 'app-ticket-client',
  templateUrl: './ticket-client.component.html',
  styleUrls: ['./ticket-client.component.scss']
})
export class TicketClientComponent implements OnInit {
    //public clients = [];
    public docTypes = [];
    public ws = [];
    public docws="";
    public clientws="";
    public hasRecentTicket = false;
    public ticket: any;
    
    //public client: "";

    

  constructor(
    public router: Router,
    public global: GlobalService,
    private clientService: ClientService,
    private _modalService: NgbModal,
    private ticketService: TicketService
  ) { }

  ngOnInit() {

    /* this.clientService.getList().then((response: any) => {
      this.clients = response.data;
    }); */
    //console.log(this.clients);

    this.docTypes = this.clientService.getListws();
  }

  pruebaws() {
    if(this.clientws === '' || this.docws === '') {
      const temp = this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text = "Debes llenar todos los campos";
    } else {
      // First, check if there is a recent ticket
      this.clientService.checkRecentTicket(this.docws, this.clientws).then((response: any) => {
        console.log("Response received:", response);
        this.hasRecentTicket = response.isRecent;
        const id_ticket = response.data.id;

    
        if (this.hasRecentTicket) {
          console.log("ticket: "+id_ticket);

          this.ticketService.getItem(id_ticket).then((item: any) => {
            console.log("item: ", item);
              this.ticket = item.data;
          //   this.comments = this.ticket.comentarios;
          //   this.selected_state = this.ticket.estado_id;
          //   this.implicatedUsers = this.ticket.implicados;
          //   this.selectedItems = JSON.parse(this.ticket.areas_asignadas);
          //   this.idTicket = this.ticket.id;
          //   this.selected_causal = this.ticket.subcausal.causal_id;
          //   this.selected_subcausal = this.ticket.subcausal_id;
          //   this.selected_radicado = this.ticket.radicado;
          //   this.selected_subradicado = this.ticket.subradicado;
          //   this.selected_prioridad = this.ticket.prioridad;
          //   this.selected_fechaVen = this.ticket.fecha_vencimiento;
          //   this.nueva_fvencimiento = this.datepipe.transform(this.selected_fechaVen, 'yyyy-MM-dd HH:mm:ss');
          //   this.old_diascoljuegos = this.ticket.dias_coljuegos;
          //   this.new_diascoljuegos = this.old_diascoljuegos;
          //   this.fecha_inc = this.ticket.fecha_incidente;
          //   this.fecha_cr = this.ticket.fecha_creacion;
          //   //console.log("dias"+this.old_diascoljuegos);
          //   //console.log("ticket"+this.ticket);
          //   this.old_state = this.ticket.estado_id;
          //   //console.log("estado old"+this.old_state);
          //   this.selectedItems.forEach(element => {this.areas_old.push(element.item_id)});
          //   //console.log("areas"+this.areas_old);
          //   //console.log("subcausal"+this.selected_subcausal);
            
          //   if (this.ticket.implicados != null && this.ticket.implicados.length > 0) {
          //     this.ticket.implicados.forEach(user => {
          //       this.addImplicatedUser(JSON.stringify(user));
          //     });
          //   }
          //   //console.log(this.listaUsuarios);
          });
          const modalRef = this._modalService.open(NgbdModalAlert);
          modalRef.componentInstance.text = "Este usuario posee un ticket reciente";

        }else {
            this.clientService.saveClientws(this.docws, this.clientws).then((response: any) => {
                const modalRef = this._modalService.open(NgbdModalAlert);
                modalRef.componentInstance.text = response.message;
    
                if (response.status === 'success') {
                    this.router.navigateByUrl('/ticket/nuevo/' + response.data.id);
                }
            }).catch((err) => {
                console.error("Error during saveClientws:", err);
                this._modalService.open(NgbdModalAlert).componentInstance.text = "Error processing your request.";
            });
        }
    }).catch((error) => {
        console.error("An error occurred while checking for recent tickets:", error);
        this._modalService.open(NgbdModalAlert).componentInstance.text = "An error occurred while checking for recent tickets.";
    });
    
    }
  }

  continuar() {
    this.clientService.saveClientws(this.docws, this.clientws).then((response: any) => {
      const modalRef = this._modalService.open(NgbdModalAlert);
      modalRef.componentInstance.text = response.message;

      if (response.status === 'success') {
          this.router.navigateByUrl('/ticket/nuevo/' + response.data.id);
      }
    }).catch((err) => {
        console.error("Error during saveClientws:", err);
        this._modalService.open(NgbdModalAlert).componentInstance.text = "Error processing your request.";
    });
  }

}
