import { Injectable } from '@angular/core';
import { Subcausal } from '../interfaces/subcausal';
import { ProxyService } from '../providers/proxy/proxy.service';

@Injectable({
  providedIn: 'root'
})
export class SubcausalService {

  constructor(
    private proxy: ProxyService
  ) { }

  public getList(query = {}) {
    return this.proxy.get('/subcausales');
  }
}
