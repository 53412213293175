import { Injectable } from '@angular/core';
import { Priority } from '../interfaces/priority';

@Injectable({
  providedIn: 'root'
})
export class PriorityService {

  constructor() { }

  public getList(): Array<Priority> {
    const states: Array<Priority> = [
      {
        id: 1,
        name: 'Alta',
        vence: 2
      },
      {
        id: 2,
        name: 'Media',
        vence: 3

      }/* ,
      {
        id: 3,
        name: 'Baja',
        vence: 10

      } */
    ];

    return states;
  }
}
