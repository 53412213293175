import { Injectable } from '@angular/core';
import { Client } from '../interfaces/client';
import { ProxyService } from '../providers/proxy/proxy.service';

interface CheckTicketResponse {
  isRecent: boolean;
  token: string;
}



@Injectable({
  providedIn: 'root'
})
export class ClientService {

  private apiName = 'bptRestApi01';
  private path = '/clients';

  constructor(
    private proxy: ProxyService
  ) { }

  public getList(query = {}) {
    
    return this.proxy.get('/clientes-lista');

  }

  public getListTable(pagina,columnas) {
    
    return this.proxy.get('/clientes'+'?pagina='+pagina+'&columnas='+columnas);

  }

  public async getListExcel(pagina, columnas, excel) {
    return await this.proxy.get('/clientes'+'?pagina='+pagina+'&columnas='+columnas+'&excel='+excel);
  }

  public async saveClientws(tipodoc, documento) {

    return await this.proxy.get('/clientes/getCliente/'+'?tipodoc='+tipodoc+'&numdoc='+documento);

  } 

  public async getCliente(id) {

    return await this.proxy.get('/clientes/getClienteId/'+'?id='+id);

  }

  public getListws() {

    const docTypes: Array<{id: string, name: string}> = [
      { id: "C.C", name: 'C.C' },
      { id: "C.E", name: 'C.E' },
      //{ id: "NIT", name: 'NIT' }
      { id: "PEP", name: 'PEP' },
      { id: "PPT", name: 'PPT' }

    ];

    return docTypes;

  }



  public getExcel(key) {
    return this.proxy.get('/clientes/descargar/'+ key);
  }


  // Método nuevo para verificar si hay un ticket reciente para un tipo y número de documento
  public async checkRecentTicket(tipodoc: string, documento: string) {
    return  await this.proxy.get('/clientes/checkticketrecent/?tipodoc='+tipodoc+'&numdoc='+documento);

  }
}
