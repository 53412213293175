import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';

import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { KeyObjectPipe } from './pipes/key-object.pipe';
import { StringifyPipe } from './pipes/stringify.pipe';
import { FilterParentPipe } from './pipes/filter-parent.pipe';
import { GetByIdPipe } from './pipes/get-by-id.pipe';
import { GeneralFilterPipe } from './pipes/general-filter.pipe';
import { TicketsFilterPipe } from './pipes/tickets-filter.pipe';
import { DaysSincePipe } from './pipes/days-since.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    KeyObjectPipe,
    StringifyPipe,
    FilterParentPipe,
    GetByIdPipe,
    GeneralFilterPipe,
    TicketsFilterPipe,
    DaysSincePipe,
  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    AppRoutingModule
  ],
  exports: [
    HeaderComponent,
    MenuComponent,
    KeyObjectPipe,
    StringifyPipe,
    FilterParentPipe,
    GetByIdPipe,
    GeneralFilterPipe,
    TicketsFilterPipe,
    DaysSincePipe,
  ]
})
export class SharedModule { }
