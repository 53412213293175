import { Injectable, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

// import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class ProxyService {
  constructor(
    public http: HttpClient,
    public global: GlobalService
  ) {
    if (localStorage.getItem('act_token') && localStorage.getItem('act_token') != null) {
      this.global.USER_TOKEN = localStorage.getItem('act_token');
    }
  }

  /**
   * Función para realizar peticiones de tipo GET al API Endpoint
   * 
   * @param endpoint Endpoint a consultar en el API sin la URL Base
   * @param get_vars Variables get en formato foo=bar&bar=foo
   * 
   * @returns Promise
   */
  public get(endpoint: string, get_vars: string = null) {
    return new Promise((resolve, reject) => {
      let full_url = this.global.API_URL + endpoint;
      const http_headers = { 'Accept': 'application/json' };

      if (this.global.USER_TOKEN) {
        http_headers['Authorization'] = 'Bearer ' + this.global.USER_TOKEN;
      }

      if (get_vars !== null) {
        full_url += '?' + get_vars;
      }

      this.http.get(full_url, { 'headers': new HttpHeaders(http_headers) })
        .subscribe(
          (response: any) => {
            if (isDevMode()) {
              console.log('Success Proxy::ProxyService::get() => ', response);
            }

            if (response != null && response.token != undefined && response.token != "") {
              localStorage.setItem('act_token', response.token);
            }

            if (response != null && response.status) {
              resolve(response);
            }

            reject(response != null ? response : { message: 'Error en el endpoint, contacte con el administrador' });
          },
          (err: any) => {
            if (isDevMode()) {
              console.log('Error Proxy::ProxyService::get() => ', err);
            }

            if (err.error && err.error.status && err.error.message) {
              resolve(err.error);
            }

            reject(isDevMode() ? err : { message: 'Error en la red' });
          });
    });
  }

  /**
   * Función para realizar peticiones de tipo POST al API Endpoint
   * 
   * @param endpoint Endpoint a consultar en el API sin la URL Base
   * @param post_vars Variables get en formato JSON
   * 
   * @returns Promise
   */
  public post(endpoint: string, post_vars: any = null) {
    return new Promise((resolve, reject) => {
      const full_url = this.global.API_URL + endpoint;
      const http_headers = { 'Accept': 'application/json' };

      if (this.global.USER_TOKEN) {
        http_headers['Authorization'] = 'Bearer ' + this.global.USER_TOKEN;
      }

      this.http.post(full_url, post_vars, { 'headers': new HttpHeaders(http_headers) })
        .subscribe(
          (response: any) => {
            if (isDevMode()) {
              console.log('Success Proxy::ProxyService::post() => ', response);
            }

            if (response != null && response.token != undefined && response.token != "") {
              localStorage.setItem('act_token', response.token);
              this.global.USER_TOKEN = response.token;
            }

            if (response != null && response.status) {
              resolve(response);
            }

            reject(response != null ? response : { message: 'Error en el endpoint, contacte con el administrador' });
          },
          (err: any) => {
            if (isDevMode()) {
              console.log('Error Proxy::ProxyService::post() => ', err);
            }

            if (err.error && err.error.status && err.error.message) {
              resolve(err.error);
            }

            reject(isDevMode() ? err : { message: 'Error en la red' });
          });
    });
  }



/*   public getws(endpoint: string, get_vars: string = null) {
    return new Promise((resolve, reject) => {
      let full_url = endpoint;
      const http_headers = { 'Accept': 'application/json' };

      if (this.global.USER_TOKEN) {
        //http_headers['Authorization'] = 'Bearer ' + this.global.USER_TOKEN;
      }

      if (get_vars !== null) {
        full_url += '?' + get_vars;
      }

      this.http.get(full_url, { 'headers': new HttpHeaders(http_headers) })
        .subscribe(
          (response: any) => {
            if (isDevMode()) {
              console.log('Success Proxy::ProxyService::get() => ', response);
            }

            if (response != null && response.token != undefined && response.token != "") {
              localStorage.setItem('act_token', response.token);
            }

            if (response != null && response.status) {
              resolve(response);
            }

            reject(response != null ? response : { message: 'Error en el endpoint, contacte con el administrador' });
          },
          (err: any) => {
            if (isDevMode()) {
              console.log('Error Proxy::ProxyService::get() => ', err);
            }

            if (err.error && err.error.status && err.error.message) {
              resolve(err.error);
            }

            reject(isDevMode() ? err : { message: 'Error en la red' });
          });
    });
  } */

}
