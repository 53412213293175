import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterParent',
  pure: false
})
export class FilterParentPipe implements PipeTransform {

  transform(items: Array<any>, conditions: { [field: string]: any }): Array<any> {
    if (typeof items.filter !== 'undefined') {
      return items.filter(item => {
        for (const field in conditions) {
          if (item[field] != conditions[field] && conditions[field] != "*" && conditions[field] != "") {
            return false;
          }
        }
        return true;
      });
    }
  }
}
