import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'daysSince'
})
export class DaysSincePipe implements PipeTransform {

  transform(fechaCreacion: string, fechaModificacion: string, estado: string): number {
    const start = moment(fechaCreacion).startOf('day'); // Comienza el día de creación
    let end;

    if (estado === 'Cerrado') {
      end = moment(fechaModificacion).startOf('day'); // Comienza el día de modificación
    } else {
      end = moment().startOf('day'); // Fecha actual al inicio del día
    }

    return end.diff(start, 'days');
  }
}

