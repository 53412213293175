import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: '[app-menu]',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(
    public router: Router,
    private global: GlobalService

  ) {

  }

  ngOnInit(
  ) {

  }

  closeSession() {
    localStorage.removeItem('user_auth');
    localStorage.removeItem('user_token');
    localStorage.removeItem('act_token');
    localStorage.removeItem('act_user');

    this.global.USER_DATA = null;
    this.global.USER_TOKEN = null;
    this.router.navigate(['/login']);
  }
}
