import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {

  constructor(
    private router: Router,
    private global: GlobalService
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('act_user') && localStorage.getItem('act_user') != null) {
      
      let differenceInMinutes = this.DifferenceInMinutes();
      if(differenceInMinutes>=60){
        console.log('mayor');
        this.CloseSession();
      }else{
        let timestamp = new Date();
        localStorage.setItem('fecha_hora_actual', JSON.stringify(timestamp));
      }
      
      setTimeout(() => {

        console.log("Retrasado");

        let differenceInMinutes = this.DifferenceInMinutes();
        
        if(differenceInMinutes>=60){
          console.log('mayor');

          this.CloseSession();
        } 
      }, 3601000);
      

      return true;

    }

    this.router.navigate(['/login']);
    return false;
  }

  DifferenceInMinutes() {
    let now = new Date();
    let storedTimestamp = new Date(JSON.parse(localStorage.getItem('fecha_hora_actual')));
    let differenceInSeconds = (now.getTime() - storedTimestamp.getTime()) / 1000;
    let differenceInMinutes = differenceInSeconds / 60;
    localStorage.setItem('differenceInMinutes', JSON.stringify(differenceInMinutes));
    console.log(differenceInMinutes);
    return differenceInMinutes;
  }


  CloseSession() {
    localStorage.removeItem('user_auth');
    localStorage.removeItem('user_token');
    localStorage.removeItem('act_token');
    localStorage.removeItem('act_user');
    localStorage.removeItem('fecha_hora_actual');

    this.global.USER_DATA = null;
    this.global.USER_TOKEN = null;
    this.router.navigate(['/login']);
  }

}
