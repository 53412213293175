import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/shared/services/global.service';
import { ProxyService } from '../../shared/providers/proxy/proxy.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalAlert } from 'src/app/app.component';



@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  
  public error = '';
  public usuario = [];
  public new_password = {
    oPassword: '',
    nPassword: '',
    nPasswordR: ''
  };
  public newData = {
    newpass: '',
    userid: '',
    oldpass: ''
  };
  public oldPassword = '';


  constructor(
    private global: GlobalService,
    private proxy: ProxyService,
    private _modalService: NgbModal,
    
  ) { }

  ngOnInit() {
    if (localStorage.getItem('act_user') != null) {
      this.global.USER_DATA = JSON.parse(localStorage.getItem('act_user'));
      this.usuario=this.global.USER_DATA;
    }

    
    console.log(this.usuario);
  }

  changePassword(){

    var pass1=this.new_password.nPassword;
    var pass2=this.new_password.nPasswordR;
    var oldpass=this.new_password.oPassword;

    if (pass1 == oldpass){
      const temp=this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text="La contraseña nueva no puede ser igual a la anterior";
    }else if(pass1.length<8){
      const temp=this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text="El número mínimo de dígitos debe ser 8";
      
    }else{

      var mayus= 0;
      var num= 0;
      //var newpass=false;

      for (let i of pass1) {

        if(parseInt(i)){
          num++;
          //console.log(i);
        
        }else if(i==i.toUpperCase()){
          mayus++;
          //console.log(i);
        
        }
        
      }

      //console.log(num);
      //console.log(mayus);

      if (mayus>0 && num>0){

        if(pass1==pass2){
          this.guardarContrasena();

        }else{
          const temp=this._modalService.open(NgbdModalAlert);
          temp.componentInstance.text="Los valores no son iguales";

        }
      }else{
        const temp=this._modalService.open(NgbdModalAlert);
        temp.componentInstance.text="La contraseña requiere mínimo una mayúscula y un número";
      } 
      
    }


  }

  guardarContrasena(){

    this.newData.newpass= this.new_password.nPassword;
    this.newData.userid= this.global.USER_DATA.id;
    this.newData.oldpass= this.new_password.oPassword;

    //console.log(this.newData.newpass);
    //console.log(this.newData.userid);
    //console.log(this.newData.oldpass);

    this.error = '';

    this.proxy.post('/newPassword', JSON.stringify(this.newData)).then(
      (response: any) => {
        if (response.status == 'success') {
          const temp=this._modalService.open(NgbdModalAlert);
          temp.componentInstance.text=response.message;
          this.camposReset();
          //alert(response.message);
        }else {
          this.error = response.message;
          const temp=this._modalService.open(NgbdModalAlert);
          temp.componentInstance.text=this.error;
        }
      },
      (err) => {
        this.error = err.message;
      }
    ); 

  }

  camposReset(){
    this.new_password.nPassword='';
    this.new_password.nPasswordR='';
    this.new_password.oPassword='';
  }

  
}
