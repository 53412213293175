import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getById',
  pure: false
})
export class GetByIdPipe implements PipeTransform {

  transform(id: any, value: any, field_id = 'id', field_return = 'name'): any {
    let data = null;

    for (const a of value) {
      if (a[field_id] == id) {
        data = a[field_return];
      }
    }

    return data;
  }

}
