import { Component, OnInit } from '@angular/core';
import { ProxyService } from 'src/app/shared/providers/proxy/proxy.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { TicketsComponent } from '../tickets/tickets.component';
import { Routes, RouterModule, Router } from '@angular/router';
import { DataSharingService } from 'src/app/data-sharing.service';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { ar } from 'date-fns/locale';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public data: any = null;
  public isFiltersCollapsed = true;
  public isFiltersCollapsed2 = true;
  public fdesdev='';
  public fhastav='';
  public fdesdeGraf='';
  public fhastaGraf='';
  public fest='';
  date: Date = new Date();
  public areaGraf= [];
  public areaTabl= [];
  public selectArea= '';
  public datosarea: any = null;
  public areasTickets = [];
  public areaTick = [];
  public area1 = false;
  public area2 = false;
  public area3 = false;
  public area4 = false;
  public fechasr = '';
  public fechasReporte= {};
  public repdesde='';
  public rephasta='';

  public filters: any = {
    'estado.nombre': '',
    id: '',
    'subcausal.nombre': '',
    'subcausal.causal.nombre': '',
    'responsable.nombre': '',
    'responsable.apellido': '',
    'responsable.gerencias.nombre':'',
    'responsable.gerencias.area':'',
    areas_asignadas: '',
    'fechafiltro.fecha_modificacion': ''

    };


  public barChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    }
  };

  public barChartLabels = [
    'Abiertos',
    'Cerrados',
    /* 'Vencidos',
    'Por vencer' */
  ];
  public barChartType = 'pie';
  public barChartLegend = true;
  public barChartData = [];
  public pieChartPlugins = [];
  public chartColors = [
    { backgroundColor: ['#F58E1C', '#0064AC', '#6BBA43','#FF0000'] }
  ];

  constructor(
    public router: Router,
    public global: GlobalService,
    private ticketService: TicketService,
    private proxy: ProxyService,
    private dataSharingService: DataSharingService,
    private modalService: NgbModal
  ) {


  }

  ngOnInit() {
    this.selectArea="";

    this.areaGraf = [
      {  name: 'Operaciones BetPlay' },
      {  name: 'BI BetPlay' },
      {  name: 'Marketing BetPlay' },
      {  name: 'SAC BetPlay' },
      {  name: 'Experiencia al cliente BetPlay' },
      {  name: 'Patrocinios BetPlay' },
      {  name: 'Control de operaciones OnLine BetPlay' },
      {  name: 'Retail BetPlay' },
      {  name: 'Gerencia OnLine' },
      {  name: 'Gerencia Jurídica' },
      {  name: 'Gerencia Financiera' },
      {  name: 'Gerencia Auditoría' },
      {  name: 'Gerencia Cumplimiento' },
    ];

    this.areaTabl = [
      {  name: 'Operaciones BetPlay' },
      {  name: 'SAC BetPlay' },
      {  name: 'Experiencia al cliente BetPlay' },
      {  name: 'Control de operaciones OnLine BetPlay' },
      {  name: 'Gerencia Jurídica' },
      {  name: 'Gerencia Cumplimiento' },
      {  name: 'Tecnología CEM' },
    ];

    var fdate= new Date();
    fdate.setDate(fdate.getDate() + 1);
    this.fhastaGraf = fdate.toISOString().substr(0, 10);
    var sdate= new Date();
    sdate.setDate(sdate.getDate() - 29);
    //this.fdesdeGraf = sdate.toISOString().substr(0, 10);
    this.fechaGrafica();
  
    console.log(this.global.USER_DATA.id);

    this.areasTabla();
    
  }

  nuevaFechaVen(event: any){
    this.filters['fechafiltro.fecha_modificacion']=this.fdesdev+'.'+this.fhastav;
    //console.log(this.filters['fechafiltro.fecha_vencimiento']);
  }



  fechaGrafica(){
    var area= this.selectArea;
    var grafdesde= this.fdesdeGraf;
    var grafhasta= this.fhastaGraf;
    console.log(area);
    this.proxy.get('/dashboard/?grafdesde='+grafdesde+'&grafhasta='+grafhasta+'&area='+area+'&datosuser='+this.global.USER_DATA.id).then((response: any) => {
      if (response.status == 'success') { 
        this.data = response.data;
        this.barChartData = [
          this.data.open_tickets_c,
          this.data.closed_tickets_c,

        ];
       }
      console.log(this.data);
    }); 
  }

  areasTabla(){
    var grafd= this.fdesdeGraf;
    var grafh= this.fhastaGraf;
    this.areaTick = [];
    this.areasTickets = [];


    this.areaTabl.forEach(element =>{
      var areat = element.name;
      console.log(areat);
      this.proxy.get('/dashboard/?grafdesde='+grafd+'&grafhasta='+grafh+'&area='+areat+'&datosuser='+this.global.USER_DATA.id).then((response: any) => {
        if (response.status == 'success') { 
          this.datosarea = response.data;
          this.areaTick = [
            areat,
            this.datosarea.open_tickets_c,
            this.datosarea.validation_tickets_c,
            this.datosarea.managed_tickets_c,
            this.datosarea.twelvedays_tickets_c

          ]
          this.areasTickets.push(this.areaTick)
         }
        //console.log(this.datosarea);
      }); 
      
    });
    console.log(this.areasTickets);


  }

  ticketsFiltrados(t){
    //console.log("este"+t);

    var filtro2 = '';

    if(this.area1){
      console.log("area1");
      filtro2 = '6';
    }else if(this.area2){
      console.log("area2");
      filtro2 = '9';
    }else if(this.area3){
      console.log("area3");
      filtro2 = '7';
    }else if(this.area4){
      console.log("area4");
      filtro2 = '12d';
    }

    /* if(t[1] == i){
      filtro2 = '6';
    }else if(t[2] == i){
      filtro2 = '9';
    }else if(t[3] == i){
      filtro2 = '7';
    }else if(t[4] == i){
      filtro2 = '12d';
    } */
    //console.log("this"+i);
    const filtros = [t[0], filtro2];
    this.dataSharingService.setFiltrosSeleccionados(filtros);
    this.router.navigateByUrl('/tickets');

  }

  modalReporte(modal){
    this.modalService.open(modal, { size: 'lg' });
  }

  descargarReporte(){
    console.log("hola reporte");
    this.ticketService.getReporte(this.fechasr).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
        console.log("descarga");
       
      }
    });
  }

  filtroReporte(){
    this.fechasReporte = {
      fechaDesde: this.repdesde ,
      fechaHasta: this.rephasta
    };
    this.fechasr = JSON.stringify(this.fechasReporte);
    //console.log(this.fechasr);
  }

  descargarExcel(area){
    area = area[0]



    //si fdesdeGraf no tiene valor popup con mensaje
    if(this.fdesdeGraf == '' || this.fhastaGraf == ''){
      alert('Seleccione un rango de fechas para descargar el reporte');
      return;
    }

    var fechasReporte = {
      fechaDesde: this.fdesdeGraf ,
      fechaHasta: this.fhastaGraf,
      area: area
    };
    var param = JSON.stringify(fechasReporte);
    console.log(param);

    this.ticketService.getReporteArea(param).then((response: any) => {
      if (response.status == 'success') {
        console.log(response);
        window.open(response.data.url, '_blank');
      }
    });
  }
}
