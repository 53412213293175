import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { TicketService } from '../services/ticket.service';

@Injectable()
export class TicketsResolver implements Resolve<any>  {
  constructor(
    private ticketSerice: TicketService
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    /* const result: any = await this.ticketSerice.getList();

    return result.data; */
  }
}
