import { Injectable } from '@angular/core';
import { Motivo } from '../interfaces/motivo';

@Injectable({
  providedIn: 'root'
})
export class MotivoService {

  constructor() { }

  public getList(): Array<Motivo> {
    const motivos: Array<Motivo> = [
      {
        id: 1,
        name: 'Fallecido'
      },
      {
        id: 2,
        name: 'Listas Restrictivas',
      },
      {
        id: 3,
        name: 'Suplantación de identidad'
      },
      {
        id: 4,
        name: 'Modificación de cédula'
      },
      {
        id: 5,
        name: 'Modificación Fecha nacimiendo, vencimiento, Expedición'
      },
      {
        id: 6,
        name: 'Modificación del tipo de documento'
      },
      {
        id: 7,
        name: 'Menor de edad'
      },
      {
        id: 8,
        name: 'Cargue de documento POPUP'
      },
      {
        id: 9,
        name: 'Posible sospecha de fraude LAFTFPADM'
      },
      {
        id: 10,
        name: 'Protección de datos'
      },
      
    ];

    return motivos;
  }
}