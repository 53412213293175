import { Injectable } from '@angular/core';
import { Causal } from '../interfaces/causal';

@Injectable({
  providedIn: 'root'
})
export class CausalService {

  constructor() { }

  public getList(): Array<Causal> {
    const causales: Array<Causal> = [
      {
        id: 1,
        name: 'Petición'
      },
      {
        id: 2,
        name: 'Queja'
      },
      {
        id: 3,
        name: 'Reclamo'
      }
      /* {
        id: 4,
        name: 'Sugerencia'
      },
      {
        id: 5,
        name: 'Felicitaciones'
      } */
    ];

    return causales;
  }
}
