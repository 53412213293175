import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public API_URL = environment.APIEndpoint;
  public BASE_URL = environment.APIBase;

  public GENERAL_DATA = null;

  public USER_TOKEN = null;
  public USER_DATA = null;

  public isNavbarCollapsed = true;

  constructor() { }
}
