import { Component, OnInit } from '@angular/core';
import { ClientService } from 'src/app/shared/services/client.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DescargaFilter } from 'src/app/shared/pipes/download-filter.pipe';


@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  providers: [DescargaFilter]
})
export class ClientsComponent implements OnInit {
  public clients: any = [];
  public selected_client: any = {};
  public isFiltersCollapsed = true;
  public listaid= [];

  public listaColumnas= {};
  public pagina= 0;
  public columnas= '';
  public excel= '';

  public nombreCliente= '';
  public apellidoCliente= '';
  public docCliente= '';
  public codCuenta= '';
  public estado= '';


  

  public busquedafiltros={
    pagina:null,
    columnas:null,
  };

  public filters: any = {
    PrimerNombre:'',
    PrimerApellido:'',
    Identificacion:'',
    AccountCode: '',
    Estado: ''
  };

  public filters2: any = {
    'estado.nombre': ''
  };

  constructor(
    private clientService: ClientService,
    private modalService: NgbModal,
    private filtrodescarga: DescargaFilter,
  ) { }

  ngOnInit() {
    this.listClients();
    
  }

  
  private listClients() {
    this.busquedafiltros.pagina= this.pagina;
    this.busquedafiltros.columnas= this.columnas;
    this.clientService.getListTable(this.busquedafiltros.pagina, this.busquedafiltros.columnas).then((response: any) => {
      this.clients = response.data;
      console.log(this.clients);
      
    });
  }
  
  downloadExcel() {
    
    this.clientService.getListExcel(this.pagina, this.columnas, this.excel ).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
       
      }
    });
  }


  /* downloadExcel() {
    var desc= this.filtrodescarga.transform(this.clients, this.filters);
    console.log(desc);

    this.listaid=[];
    desc.forEach(element => {
      this.listaid.push(element.id);
    });
    console.log(this.listaid);
    
    var lista = JSON.stringify(this.listaid);

    console.log(lista);
    this.clientService.getExcel(lista).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
      }
    });
  } */

  public showInfoClient(client, modal) {
    this.selected_client = client;
    this.modalService.open(modal, { size: 'lg' });
  }

  pasarPagina() {
    this.pagina += 1;
    this.listClients();
    console.log(this.pagina);
    console.log(this.clients.length);
    
  }

  volverPagina() {
    this.pagina -= 1;
    this.listClients();
    console.log(this.pagina);
    console.log(this.clients.length);
    
  }

  filtroColumnas(){
    this.listaColumnas = { 
      nombre: this.nombreCliente ,
      apellido: this.apellidoCliente ,
      documento: this.docCliente ,
      cuenta: this.codCuenta ,
      estado: this.estado ,
    };
    this.pagina= 0;
    this.columnas= JSON.stringify(this.listaColumnas);
    console.log(this.columnas);

    this.listClients();

  }

}
