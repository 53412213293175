import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuditoriaListComponent } from './auditoria-list/auditoria-list.component';
import { AuditoriaListAreasComponent } from './auditoria-list-areas/auditoria-list-areas.component';

const routes: Routes = [
  { path: 'estados',component: AuditoriaListComponent },
  { path: 'areas',component: AuditoriaListAreasComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuditoriaRoutingModule {}
 
