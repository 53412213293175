import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private filtrosSeleccionados: string[];

  constructor() { }

  setFiltrosSeleccionados(valores: string[]): void {
    this.filtrosSeleccionados = valores
  }

  getFiltrosSeleccionados(): string[] {
    return this.filtrosSeleccionados;
  }

}
