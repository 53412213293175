import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'generalFilter',
  pure: false
})
export class GeneralFilterPipe implements PipeTransform {

  transform(items: Array<any>, conditions: { [field: string]: any }): Array<any> {
    if (typeof items.filter !== 'undefined') {
      return items.filter(item => {
        //console.log(item);
        //console.log(conditions);
        for (const field in conditions) {
          //console.log(field);

          const condition_text = conditions[field].toLowerCase();
          var arrSplit= field.split(".");
          var item_text='';
         /* if (arrSplit[0]=='arrayfiltro'){
            //console.log(arrSplit.length);
            switch(arrSplit.length){
               case 1:
                item[field].forEach(element => {
                  
                  item_text = element.toString().toLowerCase();

                  if (!item_text.includes(condition_text) && conditions[field] !== '*' && conditions[field] !== '') {
                    return false;
                  }

                });
                return true;

                break; 

              

              case 2:
                //console.log(item[arrSplit[1]]);
                var encontrado = true;
                item[arrSplit[1]].forEach(element => {
                  
                  item_text = element[arrSplit[2]].toString().toLowerCase();
                  if (!item_text.includes(condition_text)) {
                    //console.log(item_text);
                    encontrado = false;
                    return false;
                  }

                });
                if(!encontrado){
                  return false;
                }
                return true;
                //item_text = item[arrSplit[0]][arrSplit[1]][arrSplit[2]].toString().toLowerCase();
                break;

            }
          }else*/ if (arrSplit[0]=='fechafiltro'){
            var datesplit= conditions[field].split(".");
            var fechaDesde = new Date(Date.parse(datesplit[0]+' 00:00:00'));
            var fechaValor = new Date(Date.parse(item[arrSplit[1]]));
            var fechaHasta = new Date(Date.parse(datesplit[1]+' 00:00:00'));
            var esDesde= this.compareDate(fechaDesde, fechaValor)>=0;
            var esHasta= this.compareDate(fechaValor, fechaHasta)>=0;
            if(datesplit[0]==''){
              return true;
            }else if(esDesde==true){
              if(datesplit[1]==''){
                return true;
              }else{
                  if(esHasta==true){
                    return true;
                  }
              }
            }
            console.log(datesplit[0]);
          }else{
            switch(arrSplit.length){
              case 1:
                item_text = item[field].toString().toLowerCase();
              break;
              case 2:
                item_text = item[arrSplit[0]][arrSplit[1]].toString().toLowerCase();
                break;
              case 3:
                item_text = item[arrSplit[0]][arrSplit[1]][arrSplit[2]].toString().toLowerCase();
                break;
            }
          }

          if (!item_text.includes(condition_text) && conditions[field] !== '*' && conditions[field] !== '') {
            return false;
          }
        }
        return true;
      });
    }
  }

  public compareDate(date1: Date, date2: Date): number
{
  // With Date object we can compare dates them using the >, <, <= or >=.
  // The ==, !=, ===, and !== operators require to use date.getTime(),
  // so we need to create a new instance of Date with 'new Date()'
  let d1 = new Date(date1); let d2 = new Date(date2);

  // Check if the dates are equal
  let same = d1.getTime() === d2.getTime();
  if (same) return 0;

  // Check if the first is greater than second
  if (d1 > d2) return -1;
 
  // Check if the first is less than second
  if (d1 < d2) return 1;
}


}
