import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyObject',
  pure: false
})
export class KeyObjectPipe implements PipeTransform {

  transform(value: any, ...args) {
    return Object.keys(value);
  }

}
