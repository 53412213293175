import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';

import { GlobalService } from './shared/services/global.service';
import { GuardService } from './shared/services/guard.service';
import { TicketsComponent } from './pages/tickets/tickets.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { TicketDetailComponent } from './pages/ticket-detail/ticket-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { TicketFormComponent } from './pages/ticket-form/ticket-form.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccountComponent } from './pages/account/account.component';
import { TicketClientComponent } from './pages/ticket-client/ticket-client.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';

import { AuditoriaModule } from './pages/auditoria/auditoria.module'

import {
  NgbdModalAlert,
  NgbdModalConfirm,
  NgbdModalConfirmAutofocus,
} from  './app.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TicketsComponent,
    ClientsComponent,
    TicketDetailComponent,
    TicketFormComponent,
    AccountComponent,
    TicketClientComponent,
    NgbdModalAlert,
    NgbdModalConfirm,
    NgbdModalConfirmAutofocus,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    AngularEditorModule,
    ChartsModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AuditoriaModule,
  ],
  providers: [
    GlobalService,
    GuardService,
    DatePipe,
  ],
  entryComponents: [NgbdModalConfirm,NgbdModalAlert],
  bootstrap: [AppComponent]
})
export class AppModule { }
