import { Injectable } from '@angular/core';
import { ResponsibleEntity } from '../interfaces/responsible-entity';

@Injectable({
  providedIn: 'root'
})
export class ResponsibleEntityService {

  constructor() { }

  public getList(): Array<ResponsibleEntity> {
    const responsibleEntities: Array<ResponsibleEntity> = [
      {
        id: 1,
        name: 'Operaciones BetPlay'
      },
      {
        id: 2,
        name: 'BI BetPlay',
      },
      {
        id: 3,
        name: 'Marketing BetPlay'
      },
      {
        id: 4,
        name: 'SAC BetPlay'
      },
      {
        id: 5,
        name: 'Experiencia al cliente BetPlay'
      },
      {
        id: 6,
        name: 'Patrocinios BetPlay'
      },
      {
        id: 7,
        name: 'Control de operaciones OnLine BetPlay'
      },
      {
        id: 8,
        name: 'Retail BetPlay'
      },
      {
        id: 9,
        name: 'Gerencia OnLine'
      },
      {
        id: 10,
        name: 'Gerencia Jurídica'
      },
      {
        id: 11,
        name: 'Gerencia Financiera'
      },
      {
        id: 12,
        name: 'Gerencia Auditoría'
      },
      {
        id: 13,
        name: 'Gerencia Cumplimiento'
      }
    ];

    return responsibleEntities;
  }
}
