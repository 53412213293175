import { Component, OnInit } from '@angular/core';
import { AuditoriaService } from 'src/app/shared/services/auditoria.service';

@Component({
  selector: 'app-auditoria-graficos',
  templateUrl: './auditoria-graficos.component.html',
})
export class AuditoriaGraficosComponent implements OnInit {
  expandedImage: string | null = null;

  toggleImage(imageKey: string): void {
    if (imageKey === 'image1') {
      this.expandedImage = 'assets/img/tickets_por_responsable.png';
    } else if (imageKey === 'image2') {
      this.expandedImage = 'assets/img/distribucion_semanal.png';
    }
  }

  closeImage(): void {
    this.expandedImage = null;
  }
  
  constructor(private auditoriaService: AuditoriaService) {}

  ngOnInit(): void {
  }


}
