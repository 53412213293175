import { Component, OnInit } from '@angular/core';
import { AuditoriaService } from 'src/app/shared/services/auditoria.service';



@Component({
  selector: 'app-auditoria-list',
  templateUrl: './auditoria-list.component.html'
})
export class AuditoriaListComponent implements OnInit {
  auditorias: any[] = [];
  filtroFechadesde: string = '';
  filtroFechahasta: string = '';
  public pagina= 0;

  constructor(private auditoriaService: AuditoriaService) {}





  ngOnInit(): void {
    this.filtrarAuditoria();
  }

  filtrarAuditoria(): void {
    this.auditoriaService.getAuditList(this.filtroFechadesde, this.filtroFechahasta , this.pagina).then((response: any) => {
      this.auditorias = response.data;
    });
  }

  downloadExcel(): void {

    this.auditoriaService.getAuditListExcel(this.filtroFechadesde, this.filtroFechahasta, true).then((response: any) => {
      if (response.status == 'success') {
        window.open(response.data.url);
       
      }
    });
  }
  
  pasarPagina() {
    this.pagina += 1;
    this.filtrarAuditoria();
    if (this.auditorias.length === 0) {
      this.pagina -= 1;
      this.filtrarAuditoria();
      // deshabilitar boton
      
    }
  }

  volverPagina() {
    this.pagina -= 1;
    this.filtrarAuditoria();
    
  }

}
