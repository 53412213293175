import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { ChannelService } from 'src/app/shared/services/channel.service';
import { CausalService } from 'src/app/shared/services/causal.service';
import { SubcausalService } from 'src/app/shared/services/subcausal.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ResponsibleEntityService } from 'src/app/shared/services/responsible-entity.service';
import { PriorityService } from 'src/app/shared/services/priority.service';
import { TicketService } from 'src/app/shared/services/ticket.service';
import { GlobalService } from 'src/app/shared/services/global.service';
import { environment } from 'src/environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ConsultantService } from 'src/app/shared/services/consultant.service';
import { MotivoService } from 'src/app/shared/services/motivo.service';

import { Consultants } from 'src/app/shared/interfaces/consultants';
import { Observable } from 'rxjs';
import { debounceTime, map, filter  } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalAlert } from 'src/app/app.component';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { differenceInDays } from 'date-fns';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

var arrayAreas=[];
@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.scss']
})
export class TicketDetailComponent implements OnInit {

  dropdownList = [];
  public selectedItems = [];
  dropdownSettings:IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  private number = null;
  private client_id = null;

  public ticket: any = {};
  public implicatedUsers = {};
  //public involucrados = {};

  public channels = [];
  public causals = [];
  public subcausals = [];
  public states = [];
  public responsibleEntities = [];
  public priorities = [];
  public consultants = [];
  public motivos = [];

  public comments = [];
  public comment = '';
  public selected_state = '';
  public old_state = '';
  public env;
  public file_comment: string = '';
  public idTicket= '';
  public selected_causal = '';
  public selected_subcausal = '';
  public selected_radicado = '';
  public selected_subradicado = '';
  public selected_prioridad = '';
  public selected_fechaVen = new Date();
  public fests= [];
  public nueva_fvencimiento = '';
  public clicked= false;
  public clickedcom= false;
  public estado_cambio = false;
  public new_comment = false;
  public areas_old = [];
  public areas_cambio = false;
  public new_diascoljuegos = '';
  public old_diascoljuegos = '';
  public fecha_inc = new Date();
  public fecha_cr = new Date();
  public coljuegos = false;
  public dias_habiles = '';
  public observaciones = '';
  public observacionesSalto = '';
  public ticket_sig = true;
  public ticket_ant = true;
  public currentImageUrl: SafeResourceUrl;  
  public currentFileName: string;  // Variable to hold the current file name
  public isPdf: boolean = false;
  public editCom = false;
  public comId = '';
  public numero_llamadas = 0;
  public dias_asginado = 0;
  public consultants_fil = [];
  public selectedImplicatedUser2= '';
  public busquedafiltros: any;

  public wysiwyg_config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    minHeight: '10rem',
    maxHeight: '15rem',
    placeholder: 'Escriba su comentario aquí',
    sanitize: false,
    toolbarPosition: 'top',
    defaultFontName: 'Arial',
    
  };

  private formCommentTicket: FormData = new FormData();
  public uploadDocumentName = [];
  tickets: any;
  currentTicketIndex: number;
  pagina: any;

  constructor(
    public router: Router,
    public global: GlobalService,
    private channelService: ChannelService,
    private causalService: CausalService,
    private subcausalService: SubcausalService,
    private stateService: StateService,
    private responsibleEntitieService: ResponsibleEntityService,
    private motivoService: MotivoService,
    private priorityService: PriorityService,
    private activatedRoute: ActivatedRoute,
    private ticketService: TicketService,
    private consultantService: ConsultantService,
    private _modalService: NgbModal,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    this.env = environment;
  }

  ngOnInit() {



    this.dropdownList = [
      { item_id: 1, item_text: 'Operaciones BetPlay' },
      { item_id: 2, item_text: 'BI BetPlay' },
      { item_id: 3, item_text: 'Marketing BetPlay' },
      { item_id: 4, item_text: 'SAC BetPlay' },
      { item_id: 5, item_text: 'Experiencia al cliente BetPlay' },
      { item_id: 6, item_text: 'Tecnología CEM' },
      { item_id: 7, item_text: 'Control de operaciones OnLine BetPlay' },
      { item_id: 8, item_text: 'Retail BetPlay' },
      { item_id: 9, item_text: 'Gerencia OnLine' },
      { item_id: 10, item_text: 'Gerencia Jurídica' },
      { item_id: 11, item_text: 'Gerencia Financiera' },
      { item_id: 12, item_text: 'Gerencia Auditoría' },
      { item_id: 13, item_text: 'Gerencia Cumplimiento' },
    ];
    
    this.channels = this.channelService.getList();
    this.causals = this.causalService.getList();
    this.subcausalService.getList().then((response: any) => {
      this.subcausals = response.data.datos;
      this.fests= response.data.fest;
    });
    this.states = this.stateService.getList();
    this.responsibleEntities = this.responsibleEntitieService.getList();
    this.motivos = this.motivoService.getList();
    this.priorities = this.priorityService.getList();
    

    
    this.activatedRoute.paramMap.subscribe(params => {
      this.number = params.get('number');
      
      this.ticketService.getItem(this.number).then((item: any) => {

        this.ticket = item.data;
        this.comments = this.ticket.comentarios;
        this.selected_state = this.ticket.estado_id;
        this.implicatedUsers = this.ticket.implicados;
        this.selectedItems = JSON.parse(this.ticket.areas_asignadas);
        this.idTicket = this.ticket.id;
        this.selected_causal = this.ticket.subcausal.causal_id;
        this.selected_subcausal = this.ticket.subcausal_id;
        this.selected_radicado = this.ticket.radicado;
        this.selected_subradicado = this.ticket.subradicado;
        this.selected_prioridad = this.ticket.prioridad;
        this.selected_fechaVen = this.ticket.fecha_vencimiento;
        this.nueva_fvencimiento = this.datepipe.transform(this.selected_fechaVen, 'yyyy-MM-dd HH:mm:ss');
        this.old_diascoljuegos = this.ticket.dias_coljuegos;
        this.new_diascoljuegos = this.old_diascoljuegos;
        this.fecha_inc = this.ticket.fecha_incidente;
        this.fecha_cr = this.ticket.fecha_creacion;
        this.numero_llamadas = this.ticket.numero_llamadas;
        //calcula los dias desde fecha_area_mod hasta hoy si fecha_area_mod es null se toma la fecha_creacion or is 0000-00-00 00:00:00
        if (this.ticket.fecha_area_mod == null || this.ticket.fecha_area_mod == '0000-00-00 00:00:00') {
          this.ticket.fecha_area_mod = this.ticket.fecha_creacion;
        }
        this.dias_asginado = differenceInDays(new Date(), new Date(this.ticket.fecha_area_mod));

        //console.log("dias"+this.old_diascoljuegos);
        //console.log("ticket"+this.ticket);
        this.old_state = this.ticket.estado_id;
        //console.log("estado old"+this.old_state);
        this.selectedItems.forEach(element => {this.areas_old.push(element.item_id)});
        //console.log("areas"+this.areas_old);
        //console.log("subcausal"+this.selected_subcausal);
/*         this.comments.forEach(element => {
          if (element.usuario.id == this.global.USER_DATA.id) {
            element.contenido = this.convertHtmlToText(element.contenido)
          }
        }); */
        
/*         if (this.ticket.implicados != null && this.ticket.implicados.length > 0) {
          this.ticket.implicados.forEach(user => {
            this.addImplicatedUser(JSON.stringify(user));
          });
        } */

        arrayAreas=this.selectedItems.map((v) => v.item_text.toLowerCase());

        this.consultantService.getList().then((response: any) => {
          this.consultants = response.data;
          
          // Filtra los consultores según las áreas seleccionadas
          this.consultants_fil = this.consultants.filter((v) => {
            const areaNombre = v.gerencias.nombre.toLowerCase();
            const areaArea = v.gerencias.area.toLowerCase();
            // Verifica si el nombre del área o el área coinciden con las áreas seleccionadas
            if (arrayAreas.includes(areaNombre) || arrayAreas.includes(areaArea)) {
                return true;
            }

            return false;
          });
        });



        //console.log(this.listaUsuarios);
      });
       
    });
    
    const busquedaCookie =localStorage.getItem('buscaCookies');

    if (busquedaCookie) {
      this.busquedafiltros = JSON.parse(busquedaCookie);
      this.pagina = this.busquedafiltros.pagina;
      this.ticketService.getList(
        this.busquedafiltros.pagina,
        this.busquedafiltros.filtro,
        this.global.USER_DATA.id,
        this.busquedafiltros.columnas,
        this.busquedafiltros.nocerrados
      ).then((response: any) => {
        this.tickets = response.data;
        //encontrar el ticket actual en la lista de tickets
        this.currentTicketIndex = this.tickets.findIndex(ticket => ticket.id == this.number);
        this.ticketService.setTicketNums(response.ticketsNums);

      
    }
    );
    }



  }



  
  ngAfterViewInit() {
    (document.getElementsByClassName('fa-align-left')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-align-center')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-align-right')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-align-justify')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-indent')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-outdent')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('color-label background')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-link')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-chain-broken')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-image')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-video-camera')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';
    (document.getElementsByClassName('fa-code')[0].closest('.angular-editor-button') as HTMLElement).style.display = 'none';

    //console.log("subcausal"+this.selected_subcausal);
    this.diasColjuegos();
    this.diasHabiles();

    const images = document.querySelectorAll('.comment-content img');

    images.forEach((img: HTMLImageElement) => {
      img.addEventListener('click', async (event) => {
        event.preventDefault(); // Evita el comportamiento predeterminado

        const src = img.getAttribute('src');
        if (src && src.startsWith('data:image/')) {
          try {
            // Extraer la parte base64 y convertirla en un Blob
            const base64Data = src.split(',')[1];
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);

            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'image/png' });
            const blobUrl = URL.createObjectURL(blob);

            // Abrir la URL Blob en una nueva pestaña
            const newTab = window.open(blobUrl, '_blank');
            
            // Liberar la URL Blob después de abrirla
            if (newTab) {
              newTab.onload = () => URL.revokeObjectURL(blobUrl);
            }
          } catch (error) {
            console.error("Error al abrir la imagen en una nueva pestaña:", error);
          }
        }
      });
    });

    
  }

  changeState(){
    
    if(this.selected_state != this.old_state){
      this.estado_cambio = true
    }else{
      this.estado_cambio = false
    }
    //console.log(this.estado_cambio);

  }

  
  changeAreas(){
    if (this.selectedItems.length != this.areas_old.length){
      //console.log(this.selectedItems.length+"diferente"+this.areas_old.length);
      this.areas_cambio = true;
    }else{
      
      const todosEncontrados = this.selectedItems.every((elemento1) => {
        return this.areas_old.some((elemento2) => {
          return elemento2 === elemento1.item_id;
        });
      });
      
      if (todosEncontrados) {
        //console.log(`Todos los elementos de this.selectedItems se encuentran en this.areas_old`);
        this.areas_cambio = false;
      } else {
        this.areas_cambio = true;
        //console.log(`Al menos uno de los elementos de this.selectedItems no se encuentra en this.areas_old`);
      }

    }
  }
  
  onItemSelect(item: any) {
    //console.log(this.selectedItems);
    //console.log(item);
    var unaArea=[];
    this.selectedItems.forEach(element => {unaArea.push(element.item_text.toLowerCase())});
    arrayAreas=unaArea;
    // Filtra los consultores según las áreas seleccionadas
    this.consultants_fil = this.consultants.filter((v) => {
      const areaNombre = v.gerencias.nombre.toLowerCase();
      const areaArea = v.gerencias.area.toLowerCase();

      // Verifica si el nombre del área o el área coinciden con las áreas seleccionadas
      if (arrayAreas.includes(areaNombre) || arrayAreas.includes(areaArea)) {
          return true;
      }

      return false;
    });
  }
  

/*   addImplicatedUser(user) {
    //console.log(user);
    var cons= '';
    this.consultants.forEach((value, index) => {
      if(value.correo==user){
        cons=JSON.stringify(this.consultants[index]);
      }
    });
    user = JSON.parse(cons);
    this.implicatedUsers[user.id] = user;
    //console.log(this.implicatedUsers);
  } */

    addImplicatedUser() {

      const selectedConsultant = this.consultants.find(consultant => consultant.id === +this.selectedImplicatedUser2);
      if (selectedConsultant && !this.implicatedUsers[selectedConsultant.id]) {
        this.implicatedUsers[selectedConsultant.id] = selectedConsultant;
      }
      //this.valImplicados();
      this.selectedImplicatedUser2 = '';  // Limpiar la selección después de agregar */
    }

  deleteImplicatedUser(id) {
    delete this.implicatedUsers[id];
  }


  
  public documentChangeEvent(fileInput: any) {
    //console.log(this.uploadDocumentName);
    const file_list: FileList = fileInput.target.files;
    
    if (file_list != undefined && file_list.length > 0) {
      
      for (let i = 0; i < file_list.length; i++) {
        const file: File = file_list[i];
        if(file.size < 5000001){
          this.formCommentTicket.append('documento_'+this.uploadDocumentName.length, file, file.name);
          this.uploadDocumentName.push(file.name);
        }else{
          const temp=this._modalService.open(NgbdModalAlert);
          temp.componentInstance.text="El archivo sobrepasa 1 MB";
          this.reset();
        }
      }
      /* const file: File = file_list[0];
      if(file.size < 1000001){

        this.formCommentTicket.append('documento_'+this.uploadDocumentName.length, file, file.name);
        this.uploadDocumentName.push(file.name);
      }else{
        const temp=this._modalService.open(NgbdModalAlert);
        temp.componentInstance.text="El archivo sobrepasa 1 MB";
        this.reset();
      } */
    

    }
  }

  deleteDocument(document){

    delete this.uploadDocumentName[document];
    this.formCommentTicket.delete('documento_'+document);

  }
  
  reset() {
    this.formCommentTicket= new FormData();
    this.file_comment = '';
    this.uploadDocumentName = [];
  }

  disableBtnCom(){
    this.clickedcom=true;
    this.sendComment();
    this.activatebtn();
  }

  sendComment() {
    this.formCommentTicket.append('contenido', this.comment);
    this.formCommentTicket.append('ticket_id', this.number);

    this.ticketService.sendComment(this.formCommentTicket).then((response: any) => {
      if (response.status == 'success') {
        this.comments.unshift(response.data);
        this.formCommentTicket = new FormData();
        this.comment = '';
        this.uploadDocumentName = [];
        this.new_comment = true;
      }

      const temp=this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text=response.message;

    });
  }



  updateTicket() {


    
    const formDataTicket = {
      subcausal_id: this.selected_subcausal,
      radicado: this.selected_radicado,
      subradicado: this.selected_subradicado,
      areas_asignadas: JSON.stringify(this.selectedItems),
      prioridad: this.selected_prioridad,
      estado_id: this.selected_state,
      fecha_vencimiento: this.nueva_fvencimiento,
      implicados: JSON.stringify(this.implicatedUsers),
      adjuntos: JSON.stringify(this.ticket.adjuntos),
      adjuntoscom: JSON.stringify(this.comments),
      dias_coljuegos: this.new_diascoljuegos,
      dias_h: this.dias_habiles,
      numero_llamadas: this.numero_llamadas

    };

    // si numero_llamadas cambia a 2 o 3 con respecto a valor original se llama a onNotifyTicket
    if(this.ticket.numero_llamadas != this.numero_llamadas && (this.numero_llamadas == 2 || this.numero_llamadas == 3)){
      this.onNotifyTicket("llamadas",'implicados');
    }

    // if estado se cambio a gestionado se llama a onNotifyTicket
    if(this.selected_state == '7' && this.old_state != '7'){
      this.onNotifyTicket("gestionado","responsable");
    }

    this.ticketService.update(this.number, formDataTicket).then((response: any) => {
      const temp=this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text=response.message;

      if (response.status == 'success') {
        this.ticket = response.data;
        this.comments = this.ticket.comentarios;
        this.selected_state = this.ticket.estado_id;
        
        this.router.navigateByUrl('/tickets');
/*         if (this.ticket.implicados != null && this.ticket.implicados.length > 0) {
          this.ticket.implicados.forEach(user => {
            this.addImplicatedUser();
          });
        } */
      }

    });
  }

  public searchConsultants = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    map(term => term === '' ? []
      : this.consultants.filter(
        v => (
          v.nombre + ' '
          + v.apellido + ' '
          + v.correo
        ).toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 20)
    )
  ) 

  public formatterConsultants = (x: Consultants) => x.correo;

  showModal(modal){
    this._modalService.open(modal);
    
  }

  esCreador(){
    const adminUsers = [1]; // Lista de IDs de administradores
    console.log(this.global.USER_DATA.id, this.ticket.responsable_id);
    if (this.global.USER_DATA.id == this.ticket.responsable_id || adminUsers.includes(this.global.USER_DATA.id)) {
      return true;
    }
    return false;
  }
  
  borrarTicket() {
    // Array de administradores con permisos para eliminar cualquier ticket
   
  
    // Verifica si el usuario actual es el creador del ticket o un administrador
    if (this.esCreador() == false) {
      const temp = this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text = 'No tienes permiso para eliminar este ticket.';
      return;
    }
  
    // Si la validación pasa, procede a eliminar
    this.ticketService.deleteTicket(this.idTicket).then((response: any) => {
      const temp = this._modalService.open(NgbdModalAlert);
      temp.componentInstance.text = response.message;
  
      if (response.status == 'success') {
        this.router.navigateByUrl('/tickets');
      }
    });
  }
  

  esFindeSemana(f: Date){
    if(f.getDay()==6 || f.getDay()==0){
      return true;
    }
    return false;
  }

  getStringDate(current_datetime){
    return current_datetime.getFullYear()+ "-" + (current_datetime.getMonth() + 1).toString().padStart(2, 0) + "-" + current_datetime.getDate().toString().padStart(2, 0) ;
  }

  esFestivo(fecha: Date){
    if(this.fests.includes(this.getStringDate(fecha))){
      return true;
    }
  }


  /* subcausalPri(){

     this.subcausals.forEach(subc => {
      if(subc.id==this.selected_subcausal){
        this.selected_prioridad=subc.prioridad;
        this.priorities.forEach(element => {
          if(element.id==this.selected_prioridad){
            var dia = new Date(this.ticket.fecha_creacion);
            var cont=0;
            while (cont<=element.vence) {
              if(this.esFindeSemana(dia) || this.esFestivo(dia)){
              }else{
                cont++;
              }
              dia.setDate(dia.getDate() + 1);
              //console.log(cont);
            }
            dia.setDate(dia.getDate() - 1);
            this.selected_fechaVen=dia;
            this.nueva_fvencimiento = this.datepipe.transform(this.selected_fechaVen, 'yyyy-MM-dd HH:mm:ss');
           //console.log(this.nueva_fvencimiento);
           //console.log(this.selected_prioridad);
           //console.log(this.selected_subcausal);
          }
        });
      }
    }); 
    
    
    
  } */
  
  diasColjuegos(){
    //console.log("subc"+this.selected_subcausal);
    this.subcausals.forEach(subc => {
      if(subc.id==this.selected_subcausal){
        if (subc.id==37){
          this.coljuegos=true;
          //var diacr = new Date(this.ticket.fecha_creacion);
          //const fechacr = new Date(diacr);
          var d = new Date();
          var dia = new Date(d.setDate(d.getDate()));
          const hoy = new Date(dia);
          const fechain = new Date(this.fecha_inc);
          const cantidadDias = differenceInDays(hoy, fechain);
          //console.log("dias"+cantidadDias); 
          var cont=0;
          var diashcol=0;
          while (cont<cantidadDias) {
            dia.setDate(dia.getDate() - 1);
            if(this.esFindeSemana(dia) || this.esFestivo(dia)){
              //console.log("fiesta"+dia)
            }else{
              diashcol++;
            }
            cont++;
          }
          this.new_diascoljuegos = diashcol.toString();
          //console.log("col "+this.coljuegos);
          //console.log("diain"+fechain);
          //console.log(this.new_diascoljuegos);
        }else{
          this.coljuegos=false;
          this.new_diascoljuegos = '';
          //console.log("col "+this.coljuegos);
          //console.log(this.new_diascoljuegos);
        }
      }
    });

  }

  diasHabiles(){
    var d = new Date();
    var dia = new Date(d.setDate(d.getDate()));
    const hoy = new Date(dia);
    const fechacr = new Date(this.fecha_cr);
    const cantidadDias = differenceInDays(hoy, fechacr);
    //console.log("dias"+cantidadDias); 
    var cont=0;
    var diashab=0;
    while (cont<cantidadDias) {
      dia.setDate(dia.getDate() - 1);
      if(this.esFindeSemana(dia) || this.esFestivo(dia)){
        //console.log("fiesta"+dia)
      }else{
        diashab++;
      }
      cont++;
    }
    this.dias_habiles = diashab.toString();
    //console.log("dias hab "+this.dias_habiles);

  }

  activatebtn() {
    setTimeout (() => {
      this.clickedcom=false
    }, 10000)
  }

  siguienteTicket() {
    console.log(this.pagina, this.currentTicketIndex);
    const nextIndex = this.currentTicketIndex + 1;
    this.ticket_ant = true;
    this.ticket_sig = true;
    if (nextIndex < this.tickets.length) {
      const nextTicket = this.tickets[nextIndex];
      this.navigateDetail(nextTicket.id, nextIndex);
    } else {
      // Si es el último ticket, pasar a la siguiente página
      console.log(this.pagina);
      this.pagina++;
      this.filtrar(1);

    }
  }
  
  anteriorTicket() {
    console.log(this.pagina, this.currentTicketIndex);
    const prevIndex = this.currentTicketIndex - 1;
    if(this.pagina==0 && prevIndex==0){
      this.ticket_ant = false;
    }else{
      this.ticket_ant = true;
      this.ticket_sig = true;
    }
    if (prevIndex >= 0) {
      const prevTicket = this.tickets[prevIndex];
      this.navigateDetail(prevTicket.id, prevIndex);
    } else if (this.pagina >= 1) {
      // Si es el primer ticket de la página, volver a la página anterior
      this.pagina--;
      this.filtrar(-1);
    }
  }
  
  navigateDetail(ticketId: number, index: number) {
    this.currentTicketIndex = index;  // Guarda el índice actual del ticket
  
    // Lógica de navegación hacia el detalle del ticket
    this.router.navigate(['/ticket', ticketId]);
  }

  filtrar( dir) {
    this.busquedafiltros.pagina = this.pagina;
  
    this.ticketService.getList(
      this.busquedafiltros.pagina,
      this.busquedafiltros.filtro,
      this.global.USER_DATA.id,
      this.busquedafiltros.columnas,
      this.busquedafiltros.nocerrados
    ).then((response: any) => {
      
      if(response.data.length > 0){
        this.tickets = response.data;
        this.ticketService.setTicketNums(response.ticketsNums);
        if (dir == 1) {
          this.currentTicketIndex = 0
          const nextTicket = this.tickets[this.currentTicketIndex];
          this.navigateDetail(nextTicket.id, this.currentTicketIndex);
        } else {
          this.currentTicketIndex = this.tickets.length - 1;
          const nextTicket = this.tickets[this.currentTicketIndex];
          this.navigateDetail(nextTicket.id, this.currentTicketIndex);
        }
      }else{
        if (dir == 1) {
          this.pagina--;
          this.ticket_sig = false;
        } else {
          this.pagina++;
          this.ticket_ant = false;
          
        }
      }
    });
  
    // Guardar filtros en una cookie
    localStorage.setItem('buscaCookies', JSON.stringify(this.busquedafiltros));
  }


  deleteAdjunto(document){
    //console.log(document," borrar ", this.ticket.adjuntos[document].id);
    this.ticketService.deleteAdjunto(this.ticket.adjuntos[document].id).then((response: any) => {
      if (response.status == 'success') {
        //delete this.ticket.adjuntos[document];
        //console.log('adjunto borrado', response.data);
        this.ticket.adjuntos[document].activo = 0;
      }

    });
  }

  private sanitizeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  showImageInModal(imageUrl: string, fileName: string, content: any): void {
    this.currentImageUrl = this.sanitizeUrl(imageUrl);  // Set the current image URL
    this.currentFileName = fileName;  // Set the current file name
    this.isPdf = fileName.toLowerCase().endsWith('.pdf');
    this._modalService.open(content, { size: 'xl' });  // Open the modal
  } 

    

  editCommentBtn(comment){
    //console.log(comment);
    this.editCom = true;
    this.comId = comment.id;
  }


  saveComment(id, contenido){
    //console.log(id, contenido);
    this.editCom = false;
    this.comId = '';
    this.ticketService.updateComment(id, contenido).then((response: any) => {
      if (response.status == 'success') {
        console.log('comentario actualizado', response.data["fecha_modificacion"]);
        this.comments.forEach(element => {
          if(element.id==id){
            element.fecha_modificacion = response.data["fecha_modificacion"];
          }
        });
      }

    });

  }

  onNotifyTicket(tipo: string, enviar_a: string = '') {


    this.ticketService.notifyTicket(this.idTicket,tipo, enviar_a).then((response: any) => {
      console.log(response);

    });
  }


}
