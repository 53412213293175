import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'ticketsFilter',
  pure: false
})
export class TicketsFilterPipe implements PipeTransform {

  constructor(
    private global: GlobalService,
  ) {

  }

  transform(items: Array<any>, conditions: { [field: string]: any }): Array<any> {
    if (items != null && typeof items.filter !== 'undefined') {
      return items.filter(item => {
        let flag = false;
        const search = conditions.search.toLowerCase();
        const { abiertos, cerrados, mios, proceso } = conditions;

        if (
          (abiertos && item.estado.id == 1) ||
          (cerrados && item.estado.id == 3) ||
          (proceso && item.estado.id == 2) ||
          (mios && item.responsable.id == this.global.USER_DATA.id)
        ) {
          flag = true;
        }

        if (search != '' && search != '*' && flag) {
          const id = item.id;
          const nombre = item.cliente.nombre.toLowerCase();
          const apellido = item.cliente.apellido.toLowerCase();
          const subcausal = item.subcausal.nombre.toLowerCase();
          const causal = item.subcausal.causal.nombre.toLowerCase();
          const documento = item.cliente.meta.numero_documento.toLowerCase();

          if (
            id != search &&
            !nombre.includes(search) &&
            !documento.includes(search) &&
            !apellido.includes(search) &&
            !subcausal.includes(search) &&
            !causal.includes(search)
          ) {
            flag = false;
          }
        }

        return flag;
      });
    }
  }

}
